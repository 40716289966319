import React from 'react'
import PropTypes from 'prop-types'

const Wave = props => {
	const { color, size, ...otherProps } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 657.25 229.46"
			role="img"
			aria-label="Dolphin Wave Logo"
			width={size}
			{...otherProps}
		>
			<title>Dolphin Wave Logo</title>
			<path
				d="M34,0S0,0,0,34V195.47s0,34,34,34H623.26s34,0,34-34V34s0-34-34-34Z"
				fill={color}
			/>
			<path
				d="M84.21,140.48h1.3q5.61,0,9.06-14.24,1.94-8,3.88-19.64.87-5.17,2.59-17.15t2.59-17.59a14.3,14.3,0,0,1,3.89-7.77q3.23-3.45,9.49-3.45h37.12a12.53,12.53,0,0,1,8.74,3.24,13,13,0,0,1,4.21,8.2v68.4h1.51a6,6,0,0,0,1.61-.21,3.73,3.73,0,0,0,1.41-.76,7.86,7.86,0,0,0,1.08-1.08,6.44,6.44,0,0,0,.86-1.72c.29-.79.54-1.44.76-2a12.93,12.93,0,0,0,.64-2.37c.22-1.08.4-1.94.54-2.59s.36-1.62.65-2.91.5-2.38.65-3.24q1.29-5.61,4.31-24.6,2.6-17,4.1-38.41h44.89a386.59,386.59,0,0,1-7.34,44.45q-5.17,22.46-10.57,35.82-6.27,15.54-15.86,21.58t-31.62,6H148.09A12.54,12.54,0,0,1,139,165a10.13,10.13,0,0,1-3.23-8.53V90.08s-2.7,33.77-17.37,62.27a32.94,32.94,0,0,1-6.8,9.06,21.91,21.91,0,0,1-9.17,4.86,51.35,51.35,0,0,1-10.15,1.94q-4.42.33-12.4.33H56.16a12,12,0,0,1-8.74-3.46,13.06,13.06,0,0,1-4-8.63L40,60.42h46Z"
				fill="#fff"
			/>
			<path
				d="M305.26,168.54l-.43-20.94q-14.46,21.59-42.08,23.53-15.1,1.06-23.2-6.15t-9.39-20.83q-1.29-13.59,1.3-32.58a121.24,121.24,0,0,1,5.18-21.69,46,46,0,0,1,8.31-15,55.92,55.92,0,0,1,10.25-9.39,36.94,36.94,0,0,1,13.48-5.28,109.16,109.16,0,0,1,15.43-2.16q7.13-.44,18.35-.43a232.12,232.12,0,0,1,28.7,2.26q17.47,2.28,23.73,4.64l-14.67,104Zm1.51-81.36h-9.93q-8,0-12.51,4.43t-6.69,18.66q-3,20.51-1.29,24c.71,1.72,2.66,2.44,5.82,2.15q9.06-.65,20.07-16.61Z"
				fill="#fff"
			/>
			<path
				d="M443.28,60.64H492.7a262.56,262.56,0,0,1-10.58,46.18,226.18,226.18,0,0,1-16.61,39.27q-7.77,13.82-16.73,18.13t-25.35,4.32H391.49a12.51,12.51,0,0,1-8.74-3.24,12.12,12.12,0,0,1-4-8.2L367.32,60.64h49v80.71h1.95q4.31,0,6.26-2.49t5.61-11.32q4.1-10.37,7.77-27.63,3-14.88,5.39-39.27"
				fill="#fff"
			/>
			<path
				d="M601.88,134l3,27.62q-20.5,9.5-51.15,9.5-18.12,0-30.21-3.67T505,156.24a38.45,38.45,0,0,1-8.53-17.81q-2.06-10.25-.54-24.7,1.72-17.06,6.69-28.27t13.7-17.16A56.1,56.1,0,0,1,535.75,60a122,122,0,0,1,26-2.37q32.37,0,44.45,9.93t9.07,30.21q-1.95,14-12.41,19.53t-33.56,7.22l-27,1.95q.21,6,3.56,8t11.76,1.94A389.61,389.61,0,0,0,601.88,134m-56.75-29.56,12.3-1.3q7.77-.65,10.9-2.48c2.08-1.22,3.35-3.7,3.78-7.45.28-2.44-.26-4.17-1.62-5.17s-4.14-1.44-8.31-1.3q-8,.23-11.44,3.67t-5.61,14"
				fill="#fff"
			/>
		</svg>
	)
}

Wave.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Wave.defaultProps = {
	color: '#1d1d1d',
	size: '',
}

export default Wave
